html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.5s;
  background: hsl(38, 100%, 93%) 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.light {
  background-color: #fafafa;
  color: #010101;
}

body.dark {
  background-color: #010101;
  color: #fafafa;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
}
